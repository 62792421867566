import { useEffect, useRef } from 'react';

const useDMCA = () => {
  // Set ref for DMCA badge
  const dmcaRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    const refurl = 'refurl';
    const location = document.location;
    if (dmcaRef.current) {
      dmcaRef.current.href = `${dmcaRef.current.href}${
        dmcaRef.current.href.indexOf('?') === -1 ? '?' : '&'
      }${refurl}=${location}`;
    }
  }, []);

  return dmcaRef;
};

export default useDMCA;
