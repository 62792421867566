import messages from 'components/MainNav/messages';
import { NavLink, withRouter } from 'react-router-dom';
import useDMCA from 'hooks/useDMCA';
import { Viewport } from 'components/ViewportProvider';
import React, { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SocialNetworkGroup } from '../../components/Footer';
import SVGIcon from '../SVGIcon/SVGIcon';
import CloseIcon from './CloseIcon.svg';
import Email from './Email.svg';
import KamereoSideNav from './KamereoSideNav.svg';
import Phone from './Phone.svg';
import RightArrow from './RightArrow.svg';
import SlashLine from './SlashLine.svg';
import RegisteredIndustryAndTrade from './RegisteredIndustryAndTrade.svg';
import { LANGUAGES } from 'utils/constants';
import footerMessages from '../Footer/messages';
import { DMCA } from 'components/Footer';
import { pathnameChecker } from 'utils/url';

type ISideNavSectionData = 'firstSection' | 'secondSection' | 'thirdSection' | 'fourthSection';

interface ISideNavRowData {
  name: ReactElement;
  link?: string;
  isHideArrow?: boolean;
  isInternalLink?: boolean;
  isShowSwitchLanguage?: boolean;
}
const SideNavContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #f9fafc;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  transform: translate3d(100%, 0, 0);
  &.opened {
    transform: translate3d(0, 0, 0);
  }
`;

const SideNavHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  background: #ffffff;
`;

const SideNavMenu = styled.div`
  padding: 0 16px;
  background: #ffffff;
  margin: 20px 0;
`;

const SideNavMenuItem = styled((props) => {
  const { link, isInternalLink, ...rest } = props;
  if (link) {
    if (isInternalLink) return <NavLink to={link} {...rest} />;
    return <a href={link} {...rest} />;
  }
  return <div {...rest} />;
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
`;

const SideNavMenuItemName = styled.p`
  font-size: 16px;
  color: #424242;
  font-weight: 400;
`;

const ButtonSwitchLanguage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 30px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
`;

const LanguageItem = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 0 3px;
  color: ${({ isActive }) => (isActive ? '#43A047' : '#bdbdbd')};
`;

const SideNavInfoItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 16px 0;
  gap: 22px;
  &:first-child {
    padding: 16px 0;
  }
  &:nth-child(3) {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 16px;
    gap: 19px;
  }
`;
const SideNavInfoText = styled.div`
  font-size: 16px;
  color: #333333;
  font-weight: 500;
`;

const SocialNetworkGroupWrapper = styled.div`
  padding: 2px 0 16px 0;
`;
const FooterIconWrapper = styled.div`
  padding: 6px 16px 50px;
  display: flex;
  align-items: center;
  gap: 32px;
  ${SVGIcon} {
    justify-content: left !important;
    div {
      justify-content: left !important;
    }
  }
`;
const SideNavMobile: FC<any> = (props) => {
  const { opened, onCloseSideNav, onSetLang } = props;
  const lang = window.localStorage.getItem('lang') || 'vi';
  // Set ref for DMCA badge
  const dmcaRef = useDMCA();

  const data: Record<ISideNavSectionData, ISideNavRowData[]> = {
    firstSection: [
      {
        name: <FormattedMessage {...messages.home} />,
        link: '/',
        isHideArrow: true,
        isInternalLink: true,
      },
      {
        name: <FormattedMessage {...messages.aboutUs} />,
        link: lang === 'vi' ? 'https://kamereo.vn/ve-kamereo' : 'https://kamereo.vn/about-kamereo',
      },
      {
        name: <FormattedMessage {...messages.distributionLocation} />,
        link: `https://kamereo.vn/${lang === 'vi' ? 'he-thong-phan-phoi' : 'he-thong-phan-phoi?lang=en'}`,
      },
      {
        name: <FormattedMessage {...messages.careers} />,
        link: `https://kamereo.vn/${lang === 'vi' ? 'tuyen-dung' : 'career'}`,
      },
      {
        name: <FormattedMessage {...footerMessages.news} />,
        link: `https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'tin-tuc-kamereo/' : 'kamereo-news/'}`,
      },
      {
        name: <FormattedMessage {...footerMessages.press} />,
        link: `https://kamereo.vn/blog/${lang}/${
          lang === 'vi' ? 'bao-chi-noi-gi-ve-kamereo/' : 'kamereo-in-the-newspaper/'
        }`,
      },
      {
        name: <FormattedMessage {...footerMessages.certifications} />,
        link: `https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'chung-nhan/' : 'certifications/'}`,
      },
    ],
    secondSection: [
      {
        name: <FormattedMessage {...messages.paymentMethods} />,
        link: `https://kamereo.vn/blog/wp-content/uploads/2024/11/${
          lang === 'vi' ? 'Cac-hinh-thuc-thanh-toan-tai-Kamereo.pdf' : 'Payment-Methods-at-Kamereo.pdf'
        }`,
      },
      {
        name: <FormattedMessage {...messages.deliveryPolicy} />,
        link: `https://kamereo.vn/blog/wp-content/uploads/2024/11/${lang === 'vi' ? 'Chinh-sach-giao-hang.pdf' : 'Delivery-Policy.pdf'}`,
      },
      {
        name: <FormattedMessage {...messages.exchangeAndReturnPolicy} />,
        link: `https://kamereo.vn/blog/wp-content/uploads/2024/11/${
          lang === 'vi' ? 'Chinh-sach-doi_-tra-hang-tai-Kamereo.pdf' : 'Exchange_Return-Policy-at-Kamereo.pdf'
        }`,
      },
    ],
    thirdSection: [
      {
        name: <FormattedMessage {...messages.businessTips} />,
        link: `https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'kien-thuc-kinh-doanh/' : 'business-tips/'}`,
      },
      {
        name: <FormattedMessage {...messages.foodAndLifeStyle} />,
        link: `https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'am-thuc-doi-song/' : 'food-and-lifestyle/'}`,
      },
      {
        name: <FormattedMessage {...messages.topPlaces} />,
        link: `https://kamereo.vn/blog/${lang}/${
          lang === 'vi' ? 'dia-diem-an-uong-vui-choi/' : 'place-to-eat-drink-entertain/'
        }`,
      },
    ],
    fourthSection: [
      {
        name: <FormattedMessage {...messages.language} />,
        isHideArrow: true,
        isShowSwitchLanguage: true,
      },
      {
        name: <FormattedMessage {...messages.downloadApp} />,
        link: 'https://kamereo.app.link/market',
      },
    ],
  };
  return (
    <Viewport.Consumer>
      {({ width }) => {
        return (
          <SideNavContainer className={opened ? 'opened' : ''}>
            <SideNavHeader>
              <NavLink to="/">
                <SVGIcon src={KamereoSideNav} onClick={onCloseSideNav} />
              </NavLink>
              <SVGIcon src={CloseIcon} onClick={onCloseSideNav} />
            </SideNavHeader>
            {Object.values(data).map((section, index) => (
              <SideNavMenu key={index}>
                {section.map((item, index) => (
                  <SideNavMenuItem
                    key={index}
                    link={item.link}
                    isInternalLink={item.isInternalLink}
                    onClick={() => item.link && onCloseSideNav()}
                  >
                    <SideNavMenuItemName>{item.name}</SideNavMenuItemName>
                    {!item.isHideArrow && <SVGIcon src={RightArrow} />}
                    {item.isShowSwitchLanguage && (
                      <ButtonSwitchLanguage>
                        <LanguageItem onClick={() => onSetLang(LANGUAGES.Vi)} isActive={lang === 'vi'}>
                          VI
                        </LanguageItem>
                        <SVGIcon src={SlashLine} />
                        <LanguageItem onClick={() => onSetLang(LANGUAGES.En)} isActive={lang === 'en'}>
                          EN
                        </LanguageItem>
                      </ButtonSwitchLanguage>
                    )}
                  </SideNavMenuItem>
                ))}
              </SideNavMenu>
            ))}
            <SideNavMenu>
              <SideNavInfoItem>
                <SideNavMenuItemName>
                  <FormattedMessage {...messages.doYouNeedHelp} />
                </SideNavMenuItemName>
              </SideNavInfoItem>
              <SideNavInfoItem>
                <SVGIcon src={Phone} imgAttr={{ loading: 'lazy', width: '20px', height: '18px' }} />
                <SideNavInfoText>0812 46 37 27</SideNavInfoText>
              </SideNavInfoItem>
              <SideNavInfoItem>
                <SVGIcon src={Email} imgAttr={{ loading: 'lazy', width: '24px', height: '27px' }} />
                <SideNavInfoText>info@kamereo.vn</SideNavInfoText>
              </SideNavInfoItem>
              <SideNavInfoItem>
                <SideNavMenuItemName>
                  <FormattedMessage {...messages.followUsOn} />
                </SideNavMenuItemName>
              </SideNavInfoItem>
              <SocialNetworkGroupWrapper>
                <SocialNetworkGroup />
              </SocialNetworkGroupWrapper>
            </SideNavMenu>
            <FooterIconWrapper>
              {/* <a
                rel={`noopener noreferrer ${!pathnameChecker.isHomePage(location.pathname) ? 'nofollow' : undefined}`}
                target="_blank"
                href="http://online.gov.vn/Home/WebDetails/44606"
              >
                <SVGIcon
                  src={RegisteredIndustryAndTrade}
                  imgAttr={{ loading: 'lazy', width: '100px', height: '33px' }}
                />
              </a> */}
              <a
                rel={`noopener noreferrer ${!pathnameChecker.isHomePage(location.pathname) ? 'nofollow' : undefined}`}
                ref={dmcaRef}
                target="_blank"
                href="//www.dmca.com/Protection/Status.aspx?ID=ac07edbf-327a-41ad-8737-603222e24d2d"
                title="DMCA.com Protection Status"
                className="dmca-badge"
              >
                <DMCA
                  src="https://images.dmca.com/Badges/dmca-badge-w150-2x1-04.png?ID=ac07edbf-327a-41ad-8737-603222e24d2d"
                  alt="DMCA.com Protection Status"
                  width="80px"
                  height="40px"
                />
              </a>
            </FooterIconWrapper>
          </SideNavContainer>
        );
      }}
    </Viewport.Consumer>
  );
};

export default withRouter(SideNavMobile);
