import VisuallyHidden from 'components/VisuallyHidden';
import React, { FC, memo, ReactElement } from 'react';
import styled from 'styles/styled-components';
import translations from 'translations';
import footerMessage from './messages';

const DownloadAppContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 10px;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-top: 25px;
`;

interface DownloadAppProps {
  icon: ReactElement;
  link: string;
  platform: string;
}

const DownloadApp: FC<DownloadAppProps> = (props) => {
  const { icon, link, platform } = props;

  return (
    <a rel="noopener noreferrer" target="_blank" href={link}>
      <DownloadAppContainer>
        <div>{icon}</div>
      </DownloadAppContainer>
      <VisuallyHidden asComponent="span">{`${translations(footerMessage.downloadOn)} ${platform}`}</VisuallyHidden>
    </a>
  );
};

export default memo(DownloadApp);
